import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { useRouter } from 'next/router'
import { AuthAction } from 'next-firebase-auth'
import { useEffect } from 'react'
import FirebaseAuth from 'src/components/FirebaseAuth'
import Logo from 'src/components/Logo'
import { getBaseUrl } from 'src/helpers'
import withUser from 'src/hocs/withUser'
import { useUser } from 'src/hooks/useUser'
import { appPageURL } from 'src/initAuth'
import { usePageTracker } from 'src/tracking/hooks/usePageTracker'

export const getAuthUrlWithRedirect = (path: string) =>
  `/auth/?redirect=${path}`

const branchDeploymentUrlRegex =
  /^flow-builder-([0-9a-z]{9})-arcadehq\.vercel\.app$/

const productionDeploymentUrl = 'https://app.arcade.software'

const Auth = () => {
  const user = useUser()
  const router = useRouter()

  let token: string | null = null
  let redirect: string | null = null
  if (router.query) {
    if (typeof router.query.token === 'string') {
      token = router.query.token
    }
    if (typeof router.query.redirect === 'string') {
      redirect = router.query.redirect
    }
  }

  let branch: string | null = null
  const branchRegexResult = branchDeploymentUrlRegex.exec(window.location.host)
  if (branchRegexResult) {
    branch = branchRegexResult[1]
  }

  usePageTracker('Login', {})

  useEffect(() => {
    if (!router) {
      return
    }

    // Don't let unverified users access the app
    if (user.isLoggedIn && !user.emailVerified) {
      user.signOut()
      return
    }

    if (user.isLoggedIn) {
      // If user is already logged in, redirect based on URL parameter (or else to the default page)
      if (
        redirect &&
        (redirect.startsWith('/') || redirect.startsWith(getBaseUrl())) // Don't allow redirects outside of the app
      ) {
        router.push(redirect)
      } else {
        router.push(appPageURL)
      }
    } else if (token) {
      // If user is not logged in, but the URL contains a custom token, log them in with that token
      const auth = getAuth()
      signInWithCustomToken(auth, token)
    } else if (branch) {
      // If user is not logged in, and this is a Vercel preview deployment,
      // we will not be able to log them in via Google auth.
      // Instead, redirect them to the production deployment
      // where they will log in, generate a custom token, then come back here
      const url = `${productionDeploymentUrl}/deployment/${branch}`
      window.location.href = url
    }
  }, [user, router, redirect, token, branch])

  if (user.isLoggedIn || token || branch) {
    return null
  }

  return (
    <div className='flex items-center min-h-screen bg-white dark:bg-neutral-900'>
      <div className='container mx-auto'>
        <div className='max-w-md mx-auto my-10'>
          <div className='text-center'>
            <Logo className='h-6' square={false} />
            <h1 className='my-10 text-4xl font-bold text-neutral-focus'>
              Welcome to Arcade
            </h1>
          </div>
          <FirebaseAuth />
        </div>
      </div>
    </div>
  )
}

export default withUser({
  whenAuthed: AuthAction.RENDER,
  whenUnauthedBeforeInit: AuthAction.RETURN_NULL,
  whenUnauthedAfterInit: AuthAction.RENDER,
})(Auth)
